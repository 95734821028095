import { createApp, h, provide } from 'vue';
import { createApolloProvider } from '@vue/apollo-option';
import { DefaultApolloClient } from '@vue/apollo-composable';
import VueGTag from 'vue-gtag';
import { sentryInit } from '@/services/sentry';
import analyticsConfig from '../analytics-config';
import App from '@/App';
import router from '@/router';
import store from '@/store';
import defaultClient from '@/services/apollo-client';
import '@/styles/main.scss';
import '@cyhnkckali/vue3-color-picker/dist/style.css';

const apolloProvider = createApolloProvider({ defaultClient });

const app = createApp({
  setup: () => {
    provide(DefaultApolloClient, defaultClient);
  },
  render: () => h(App),
});

sentryInit(app);
app.use(router);
app.use(store);
app.use(apolloProvider);
app.use(VueGTag, analyticsConfig);
app.mount('#app');
