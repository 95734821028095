<template>
  <Navbar />
  <router-view class="app__main" />
  <Notifications />
</template>

<script>
import Navbar from '@/components/common/Navbar';
import Notifications from '@/components/common/Notifications';

export default {
  components: {
    Navbar,
    Notifications,
  },
};
</script>

<style lang="scss">
.app__main {
  display: flex;
  width: 100%;
  height: calc(100% - #{$navbarHeight});
  position: relative;
}
</style>
